import defaultFont from "./DeafultFont.js";
import botao from "./assets/button.png";
import botaoRetangular from "./assets/rectangularButton.png";

export default class Button {
    /**
     * @param {Phaser.Scene} scene
     */
    constructor(scene, {
        label, position, onclick, color, size = 90,fontSize = "35px",type= "sqrButton"
    }) {
        let scale = 0;
        let sizeY
        if(type === "sqrButton"){
            scale = size/30
            sizeY = size
        }else{
            scale = size/46;
            sizeY = size/2.4210526315789473684210526315789
        }
        this.sprite = scene.add.sprite(position.x, position.y, type).setScale(scale).setOrigin(0);
        this.sprite.setInteractive();

        if (color === "green"){
            color = 0x4D8C4D;
        }else if(color === "red"){
            color = 0x942637;
        }

        this.label = scene.add.text(position.x + size / 2, position.y + sizeY / 2, label, {
            ...defaultFont,
            fontSize: fontSize, fill: color
        }).setOrigin(0.5);


        this.sprite.on("pointerout", () => {
            this.sprite.setFrame(0)
        });

        this.sprite.on("pointerover", () => {
            this.sprite.setFrame(1)
        });

        this.sprite.on("pointerdown", () => {
            this.sprite.setFrame(2);
            if (onclick instanceof Function) {
                onclick.apply(this);
            }
        });

        this.sprite.on("pointerup", () => {
            this.sprite.setFrame(1)
        });
    }

    /**
     * @param {Phaser.Scene} scene
     */
    static load(scene) {
        scene.load.spritesheet('sqrButton', botao, {frameWidth: 30, frameHeight: 30});
        scene.load.spritesheet('recButton', botaoRetangular, {frameWidth: 46, frameHeight: 19});
    }

    /**
     * @param {Phaser.Scene} scene
     */
    updateButton = function (playerColor,turnManager,scene){
        //atualiza o último botão pressionado
        turnManager.setLastButton(this);
        turnManager.setAnswer(this.label.text);

        //altera a cor do botão pressionado
        if (turnManager.currentPlayer === "red" && turnManager.currentState === "marcar") {
            //"#942637" vermelho
            //"#4D8C4D" verde
             
            if(turnManager.checkAnswer()) {
                //greenPoints

                if(this.painted) {
                    if(this.label.style.color === "#942637") {
                        this.label.setColor("#4D8C4D");
                        turnManager.incrementGreenPoints();
                        turnManager.decrementRedPoints();
                    } 
                } else {
                    this.label.setColor("#4D8C4D");
                    turnManager.incrementGreenPoints();
                    this.painted = true;
                }
            } else {
                if(this.painted) {
                    if(this.label.style.color === "#4D8C4D") {
                        this.label.setColor("#942637");
                        turnManager.incrementRedPoints();
                        turnManager.decrementGreenPoints();
                    } 
                } else {
                    this.label.setColor("#942637");
                    turnManager.incrementRedPoints();
                    this.painted = true;
                }
            }          

        }else if (turnManager.currentPlayer === "green" && turnManager.currentState === "marcar") {
            if(turnManager.checkAnswer()) {
                //redPoints
                
                if(this.painted) {
                    if(this.label.style.color === "#4D8C4D") {
                        this.label.setColor("#942637"); 
                        turnManager.incrementRedPoints();
                        turnManager.decrementGreenPoints();
                    } 
                } else {
                    this.label.setColor("#942637"); 
                    turnManager.incrementRedPoints();
                    this.painted = true;
                }
            } else {
                if(this.painted) {
                    if(this.label.style.color === "#942637") {
                        this.label.setColor("#4D8C4D");
                        turnManager.incrementGreenPoints();
                        turnManager.decrementRedPoints();
                    }
                } else {
                    this.label.setColor("#4D8C4D");
                    turnManager.incrementGreenPoints();
                    this.painted = true;
                }
            }          


        }else if (  (turnManager.currentState === "marcar" && turnManager.currentPlayer === "red") || (turnManager.currentPlayer === "green" && turnManager.currentState === "marcar")) {
            this.label.setColor("#ffffff");

        }else if (turnManager.currentState === "selecionar" && this.painted === false) {
                turnManager.selectNumber(scene,this.label.text);
                turnManager.setMainNumber(this.label.text);
        }
    }

    painted = false;

}