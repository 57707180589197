/** @type {import("../typings/phaser")} */
import {LoadScene} from './scenes/LoadScene.js'
import {MenuScene} from "./scenes/MenuScene.js"
import {PlayScene} from "./scenes/PlayScene.js"
import {WinScene} from "./scenes/WinScene.js"
import {Modo2Scene} from "./scenes/Modo2Scene.js";
import { InstructionsGame1_2 } from './scenes/instructionsGame1_2.js';
import { InstructionsGame1_1 } from './scenes/instructionsGame1_1.js';
import { InstructionsGame2_1 } from './scenes/instructionsGame2_1.js';
import { InstructionsGame2_2 } from './scenes/instructionsGame2_2.js';


let game = new Phaser.Game({
        width: 1200,
        height: 900,
        parent: "meu-jogo",
        scene: [LoadScene, MenuScene, PlayScene, Modo2Scene, WinScene, InstructionsGame1_1, InstructionsGame1_2, InstructionsGame2_1, InstructionsGame2_2],
        render: {
            pixelArt: true
        },

    });
export default game;