import {CST} from "../CST";
import game from "../main.js";
import {alternateFont, hanalei} from "../DeafultFont.js";
import Button from "../Button-Modo2";

export class MenuScene extends Phaser.Scene{
    constructor() {
        super({
            key: CST.SCENES.MENU
        });
    }
    init(){
    }
    preload(){
        Button.load(this);
    }
    create(){

        window.addEventListener('resize', resize);
        resize();

        //fundo game
        this.add.rectangle(0, 0, game.config.width, game.config.height, 0x070909).setOrigin(0).setDepth(0);

        //chão
        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width, 150, 0x206623).setDepth(0).setOrigin(0)
        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width, 150, 0x206623).setDepth(0).setOrigin(0)

        //logo
        this.add.image((this.game.renderer.width/2), this.game.renderer.height*0.85, "logo").setScale(1).setOrigin(0.5,0.5);

        //titulo
        this.add.text((this.game.renderer.width/2), (this.game.renderer.height * 0.10),"Dino Divisores",{...alternateFont, color: '#e0e0e0', fontSize: 130, align: "center"}).setDepth(5).setOrigin(0.5, 0.5);
        this.add.text((this.game.renderer.width/2), (this.game.renderer.height * 0.10),"Dino Divisores",{...hanalei, color: '#415575', fontSize: 130, align: "center"}).setDepth(5).setOrigin(0.5, 0.5);
        
        //dinos
        this.add.image((this.game.renderer.width/4), this.game.renderer.height*0.70, "shadow").setScale(15);
        this.add.image((this.game.renderer.width/4), this.game.renderer.height*0.70, "dinoVerde").setScale(15);
        
        this.add.image((this.game.renderer.width*0.8), this.game.renderer.height*0.70, "shadow").setScale(15);
        this.add.image((this.game.renderer.width*0.8), this.game.renderer.height*0.70, "dinoVermelho").setScale(15).toggleFlipX();

        //button play     
        let playGame1 = new Button(this, {label: 'Jogo dos\nfatores', position: new Phaser.Math.Vector2(330, 230), fontSize: "20px", color:"#e0e0e0", type:"recButton", size: 230, onclick: () => {this.scene.start(CST.SCENES.PLAY, "Hello play 1")}})

        let instructionsGame1 = new Button(this, {label: "Instruções", position: new Phaser.Math.Vector2(330, 350), fontSize: "20px", color:"#e0e0e0", type:"recButton", size: 230, onclick: () => {this.scene.start(CST.SCENES.INSTRUCTIONSGAME1_1, "Hello instructions 1")}})

        let playGame2 = new Button(this, {label: "Dino\ndivisores", position: new Phaser.Math.Vector2(670, 230), fontSize: "20px", color:"#e0e0e0", type:"recButton", size: 230, onclick: () => {this.scene.start(CST.SCENES.MODO2, "Hello play 2")}})

        let instructionsGame2 = new Button(this, {label: "Instruções", position: new Phaser.Math.Vector2(670, 350), fontSize: "20px", color:"#e0e0e0", type:"recButton", size: 230, onclick: () => {this.scene.start(CST.SCENES.INSTRUCTIONSGAME2_1, "Hello instructions 2")}})

        // let play = new Button(this, {label: "Jogar", position: new Phaser.Math.Vector2(this.game.renderer.width*0.5-110, this.game.renderer.height*0.5-160), 
        // fontSize: "30px", color:"#e0e0e0", type:"recButton", size: 220, onclick: () => {this.scene.start(CST.SCENES.INSTRUCTIONS, "Hello instructions")}})

        function resize(){
            let canvas = game.canvas, width = document.getElementById("meu-jogo").offsetWidth, height = window.innerHeight;
            let wratio = width / height, ratio = canvas.width / canvas.height;

            if (wratio < ratio) {
                canvas.style.width = width + 'px';
                canvas.style.height = (width / ratio) + 'px';
            } else {
                canvas.style.width = (height * ratio) + 'px';
                canvas.style.height = height + 'px';
            }
        }
    }


}