import defaultFont from "./DeafultFont.js";
import botao from "./assets/button.png";
import botaoRetangular from "./assets/rectangularButton.png";
export default class Button {
    /**
     * @param {Phaser.Scene} scene
     */
    constructor(scene, {
        label, position, onclick, color, size = 90,fontSize = "35px",type= "sqrButton"
    }) {
        let scale = 0;
        let sizeY
        if(type === "sqrButton"){
            scale = size/30
            sizeY = size
        }else{
            scale = size/46;
            sizeY = size/2.4210526315789473684210526315789
        }
        this.sprite = scene.add.sprite(position.x, position.y, type).setScale(scale).setOrigin(0);
        this.sprite.setInteractive();

        if (color === "green"){
            color = 0x4D8C4D;
        }else if(color === "red"){
            color = 0x942637;
        }

        this.label = scene.add.text(position.x + size / 2, position.y + sizeY / 2, label, {
            ...defaultFont,
            fontSize: fontSize, fill: color
        }).setOrigin(0.5);


        this.sprite.on("pointerout", () => {
            this.sprite.setFrame(0)
        });

        this.sprite.on("pointerover", () => {
            this.sprite.setFrame(1)
        });

        this.sprite.on("pointerdown", () => {
            this.sprite.setFrame(2);
            if (onclick instanceof Function) {
                onclick.apply(this);
            }
        });

        this.sprite.on("pointerup", () => {
            this.sprite.setFrame(1)
        });
    }

    /**
     * @param {Phaser.Scene} scene
     */
    static load(scene) {
        scene.load.spritesheet('sqrButton', botao, {frameWidth: 30, frameHeight: 30});
        scene.load.spritesheet('recButton', botaoRetangular, {frameWidth: 46, frameHeight: 19});
    }

    /**
     * @param {Phaser.Scene} scene
     */ 
    updateButton = function (playerColor,turnManager,scene){
        //atualiza o último botão pressionado
        if(this.painted === false) {
            if(turnManager.getLastButton() != null && turnManager.currentState === "selecionar") {
                turnManager.getLastButton().label.setColor("#e0e0e0");
            }
    
            turnManager.setLastButton(this);
    
            //altera a cor do botão pressionado
            if (turnManager.currentPlayer === "red" && turnManager.currentState === "marcar" && turnManager.roundNumberSelected.indexOf(this) === -1 && this.painted === false) {
                this.label.setColor("#4D8C4D");
                turnManager.addroundNumberSelected(this);
                turnManager.setLastButton(null);
    
            }else if (turnManager.currentPlayer === "green" && turnManager.currentState === "marcar" && turnManager.roundNumberSelected.indexOf(this) === -1 && this.painted === false) {
                this.label.setColor("#942637");
                turnManager.addroundNumberSelected(this);
                turnManager.setLastButton(null);
    
            }else if (  (turnManager.roundNumberSelected.indexOf(this) !== -1 && this.painted === false && turnManager.currentState === "marcar" && turnManager.currentPlayer === "red") || (turnManager.currentPlayer === "green" && turnManager.currentState === "marcar" && turnManager.roundNumberSelected.indexOf(this) !== -1 && this.painted === false)) {
                this.label.setColor("#ffffff");
                turnManager.roundNumberSelected.splice(turnManager.roundNumberSelected.indexOf(this),1);
                turnManager.setLastButton(null);
            }else if (turnManager.currentState === "selecionar" && this.painted === false) {
                turnManager.selectNumber(scene,this.label.text);
    
                if(turnManager.currentPlayer == "green") {
                    this.label.setColor("#4D8C4D");
                } else {
                    this.label.setColor("#942637");
                }

                turnManager.addroundNumberSelected(this);
            }
        }
    }

    painted = false;

}