import defaultFont from "./DeafultFont.js";
import {CST} from "./CST.js";
import { Modo2Scene } from "./scenes/Modo2Scene.js";

export default class TurnManager {
    /**
     * @param {Phaser.Scene} scene
     */
    updateTurns = function (gameScene,labelGreenPoints,labelRedPoints) {
        debugger;
        if(this.currentPlayer === "green"){
            this.greenTurnText.setVisible(false);
            this.greenSelectText.setVisible(false);
            this.redTurnText.setVisible(true);
            this.redSelectText.setVisible(true);

            if (this.currentState === "marcar"){
                this.redSelectText.setText("Selecione um número e aperte confirmar");
                this.currentState = "selecionar";
                this.currentPlayer = "red";
            }else{
                this.redSelectText.setText("Marque os números que são divisores\ndo número escolhido");
                this.currentState = "marcar";

            }
        }else if (this.currentPlayer === "red"){
            this.greenTurnText.setVisible(true);
            this.greenSelectText.setVisible(true);
            this.redTurnText.setVisible(false);
            this.redSelectText.setVisible(false);

            if (this.currentState === "marcar"){
                this.greenSelectText.setText("Selecione um número e aperte confirmar");
                this.currentState = "selecionar";
                this.currentPlayer = "green";
            }else{
                this.greenSelectText.setText("Marque os números que são divisores\ndo número escolhido");
                this.currentState = "marcar";

            }
        }

        labelGreenPoints.setText(this.greenPoints);
        labelRedPoints.setText(this.redPoints);

        if (this.greenPoints + this.redPoints === 24){
            if (this.greenPoints > this.redPoints){
                gameScene.scene.start(CST.SCENES.WIN, {winner: "Verde", color: "green", redPoints: this.redPoints, greenPoints: this.greenPoints});
            }else if (this.greenPoints < this.redPoints){
                gameScene.scene.start(CST.SCENES.WIN, {winner: "Vermelho", color: "red", redPoints: this.redPoints, greenPoints: this.greenPoints});
            }else{
                gameScene.scene.start(CST.SCENES.WIN, {winner: "Empate", color: "gray", redPoints: this.redPoints, greenPoints: this.greenPoints});
            }
        }

        this.roundNumberSelected.length = 0;
    }
    /**
     * @param {Phaser.Scene} scene
     */
    selectNumber = function (scene,number){
        if(this.currentNumberText === null) {
            this.currentNumberText = scene.add.text(1050, 150, number, {...defaultFont, fontSize: "50px"})
        }else{
            this.currentNumberText.setText(number);
        }
    }

    load = function (scene){
        this.greenTurnText= scene.add.text(220,60,"Vez do Verde",{...defaultFont, fontSize: "50px", fill: "#206623"});
        this.redTurnText = scene.add.text(220,60,"Vez do Vermelho",{...defaultFont, fontSize: "50px", fill: "#942637FF"});
        this.redSelectText = scene.add.text(220,130,"Selecione um número e aperte confirmar",{...defaultFont, fontSize: "20px", fill: "#942637FF"})
        this.greenSelectText = scene.add.text(220,130,"Selecione um número e aperte confirmar",{...defaultFont, fontSize: "20px", fill: "#206623"});
        this.greenTurnText.setVisible(true);
        this.greenSelectText.setVisible(true);
        this.redTurnText.setVisible(false);
        this.redSelectText.setVisible(false);
        this.currentPlayer = "green";
        this.currentState = "selecionar";
    }
    currentPlayer = "";
    currentState = "";

    lastButton = null;

    mainNumber = null;
    answer = null;
    setMainNumber = function (number) {
        this.mainNumber = parseInt(number);
    }
    setAnswer = function (answer) {
        this.answer = parseInt(answer);
    }

    getLastButton = function (){
        return this.lastButton
    }
    setLastButton = function (button){
        this.lastButton = button;
    }

    greenTurnText = ""
    redTurnText = ""

    currentNumberText = null;

    roundNumberSelected = [];

    redSelectText = ""
    greenSelectText = ""

    greenPoints = 0;
    redPoints = 0;

    incrementGreenPoints = function (){
        this.greenPoints++;
    }
    decrementGreenPoints = function (){
        if(this.greenPoints === 0) {
            return;
        }
        this.greenPoints--;
    }
    incrementRedPoints = function (){
        this.redPoints++;
    }
    decrementRedPoints = function (){
        if(this.redPoints === 0) {
            return;
        }
        this.redPoints--;
    }

    checkAnswer = function (){

        if(this.mainNumber % this.answer != 0) {
            return false;
        }

        return true;
    }

    calculatePoints = function (){

    }
}
