const defaultFont = {
    fontFamily: '"Press Start 2P"',
    fontSize: '40px',
    align: "center",
    //fill: "#ffffff"
}

const alternateFont = {
    fontFamily: '"Hanalei Fill"',
    fontSize: '40px',
    align: "center"
}

const hanalei = {
    fontFamily: '"Hanalei"', 
    fontSize: '40px',
    align: 'center'
}


export default defaultFont;
export { alternateFont, hanalei };
