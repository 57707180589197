import {CST} from "../CST";
import game from "../main.js";
import {alternateFont, hanalei} from "../DeafultFont.js";
import Button from "../Button-Modo2";

export class WinScene extends Phaser.Scene{
    constructor() {
        super({
            key: CST.SCENES.WIN
        });
    }
    init(data){
        this.resultado = data.winner;
        this.color = data.color;
        this.redPoints = data.redPoints;
        this.greenPoints = data.greenPoints;
    }
    preload(){
        Button.load(this);
    }
    create(){
        window.addEventListener('resize', resize);
        resize();

        this.add.rectangle(0, 0, game.config.width, game.config.height, 0x070909).setOrigin(0).setDepth(0);

        //chão
        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width, 150, 0x206623).setDepth(0).setOrigin(0)
        this.add.rectangle(0,this.game.renderer.height-150,this.game.renderer.width, 150, 0x206623).setDepth(0).setOrigin(0)

        //logo
        this.add.image(this.game.renderer.width/2, this.game.renderer.height * 0.85, "logo").setScale(1).setDepth(3).setOrigin(0.5, 0.5);

        if (this.resultado === "Empate") {
            this.add.text((this.game.renderer.width/2), (this.game.renderer.height * 0.10), "O Jogo Empatou", {
                ...hanalei,
                color: "#415575",
                fontSize: 90
            }).setOrigin(0.5, 0.5);
            this.add.text((this.game.renderer.width/2), (this.game.renderer.height * 0.10), "O Jogo Empatou", {
                ...alternateFont,
                color: this.color,
                fontSize: 90,
                align: "center"
            }).setDepth(5).setOrigin(0.5, 0.5);

            this.add.image(this.game.renderer.width*0.75, this.game.renderer.height*0.75, "shadow").setScale(10);
            this.add.image(this.game.renderer.width*0.75, this.game.renderer.height*0.75, "dinoVermelho").setScale(10).toggleFlipX();

            this.add.image(this.game.renderer.width*0.25, this.game.renderer.height*0.75, "shadow").setScale(10);
            this.add.image(this.game.renderer.width*0.25, this.game.renderer.height*0.75, "dinoVerde").setScale(10);

        }else{
            this.add.text((this.game.renderer.width/2), (this.game.renderer.height * 0.10), `O jogador ${this.resultado} foi o Vencedor!`, {
                ...hanalei,
                color: "#415575",
                fontSize: 60
            }).setOrigin(0.5, 0.5);
            this.add.text((this.game.renderer.width/2), (this.game.renderer.height * 0.10), `O jogador ${this.resultado} foi o Vencedor!`, {
                ...alternateFont,
                color: this.color,
                fontSize: 60
            }).setOrigin(0.5, 0.5);

            let videoDino;

            if(this.resultado == "Verde"){
                this.add.image(this.game.renderer.width*0.75, this.game.renderer.height*0.75, "shadow").setScale(10);
                this.add.image(this.game.renderer.width*0.75, this.game.renderer.height*0.75, "dinoVermelho").setScale(10).toggleFlipX();

                this.add.image(this.game.renderer.width*0.25, this.game.renderer.height*0.75, "shadow").setScale(10);
                videoDino = this.add.video(this.game.renderer.width*0.25, this.game.renderer.height*0.59, "dinoVerdeWinner");
            } else{
                this.add.image(this.game.renderer.width*0.25, this.game.renderer.height*0.75, "shadow").setScale(10);
                this.add.image(this.game.renderer.width*0.25, this.game.renderer.height*0.75, "dinoVerde").setScale(10);

                this.add.image(this.game.renderer.width*0.75, this.game.renderer.height*0.75, "shadow").setScale(10);
                videoDino = this.add.video(this.game.renderer.width*0.75, this.game.renderer.height*0.59, "dinoVermelhoWinner");
            }

            videoDino.play(true);
            videoDino.setLoop(true);
        }

        this.add.text((this.game.renderer.width/2), (this.game.renderer.height * 0.35), `${this.greenPoints} x ${this.redPoints}`, {
            ...alternateFont,
            color: '#e0e0e0',
            fontSize: 90,
            align: "center"
        }).setDepth(5).setOrigin(0.5, 0.5);




        this.dinoVerdeAnimation = this.add.sprite(200, 200, "dinoVerdeAnimation")


        let playAgain = new Button(this, {label: "Finalizar", position: new Phaser.Math.Vector2(this.game.renderer.width*0.5 - 150, this.game.renderer.height * 0.50),
        fontSize: "30px", color:"#ffffff", type:"recButton", size: 300, onclick: () => {this.scene.start(CST.SCENES.MENU, "Menu")}})

        function resize(){
            let canvas = game.canvas, width = document.getElementById("meu-jogo").offsetWidth, height = window.innerHeight;
            let wratio = width / height, ratio = canvas.width / canvas.height;

            if (wratio < ratio) {
                canvas.style.width = width + 'px';
                canvas.style.height = (width / ratio) + 'px';
            } else {
                canvas.style.width = (height * ratio) + 'px';
                canvas.style.height = height + 'px';
            }
        }
    }
}