export const CST = {
    SCENES:{
        LOAD: "LOAD",
        MENU: "MENU",
        PLAY: "PLAY",
        MODO2: "MODO2",
        INSTRUCTIONSGAME1_1: "INSTRUCTIONSGAME1_1",
        INSTRUCTIONSGAME1_2: "INSTRUCTIONSGAME1_2",
        INSTRUCTIONSGAME2_1: "INSTRUCTIONSGAME2_1",
        INSTRUCTIONSGAME2_2: "INSTRUCTIONSGAME2_2",
        WIN: "WIN",
    }
}